import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { useState } from 'react';

// Material-UI components
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import construction from 'assets/images/maintenance/under-construction.svg';
import { Collapse, Grid } from '@mui/material';
import { Stack } from '@mui/system';

// ==============================|| ERROR BOUNDARY COMPONENT ||============================== //

export default function ErrorBoundary() {
  const error = useRouteError();
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = () => setShowDetails(!showDetails);

  // Common error messages based on HTTP status codes
  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        return <Alert color="error">Error 404 - This page doesn't exist!</Alert>;
      case 401:
        return <Alert color="error">Error 401 - You aren't authorized to see this</Alert>;
      case 503:
        return <Alert color="error">Error 503 - Looks like our API is down</Alert>;
      case 418:
        return <Alert color="error">Error 418 - Contact administrator</Alert>;
      default:
        return <Alert color="error">An unknown error occurred. Please try again later.</Alert>;
    }
  }

  // Fallback for other errors
  const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred.';
  const errorStack = error instanceof Error ? error.stack : '';

  return (
    <Grid container spacing={4} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh', py: 2 }}>
      <Grid item xs={12}>
        <Box sx={{ width: { xs: 300, sm: 480 } }}>
          <img src={construction} alt="An error occurred" style={{ width: '100%', height: 'auto' }} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography align="center" variant="h1">
            Unexpected Error
          </Typography>
          <Typography color="text.secondary" align="center" sx={{ width: '85%' }}>
            Hey! Please let us know if you're exeperiencing issues with this feature!
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Button component={Link} to={'https://hackarest.com/support'} variant="outlined">
              Support
            </Button>
            <Button component={Link} to={'/'} variant="contained">
              Home
            </Button>
          </Stack>
        </Stack>
      </Grid>
      {/* Details for Nerds Section */}
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button variant="text" size="small" color="secondary" onClick={toggleDetails} sx={{ textTransform: 'none', fontSize: '0.775rem' }}>
          {showDetails ? 'Hide Details for Nerds' : 'Show Details for Nerds'}
        </Button>
        <Collapse in={showDetails} timeout="auto" unmountOnExit>
          <Box
            sx={{
              mt: 2,
              p: 2,
              border: '1px solid #ccc',
              borderRadius: 1,
              backgroundColor: '#f9f9f9',
              maxWidth: '85%',
              textAlign: 'left',
              wordBreak: 'break-word',
              margin: '0 auto',
            }}
          >
            <Typography variant="body2" color="error" sx={{ fontWeight: 'bold', mb: 1 }}>
              Error Message:
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {errorMessage || 'No error message available.'}
            </Typography>
            <Typography variant="body2" color="error" sx={{ fontWeight: 'bold', mb: 1 }}>
              Stack Trace:
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {errorStack || 'No stack trace available.'}
            </Typography>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
}
