import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import EuroOutlined from '@ant-design/icons/EuroCircleOutlined';
import { Link } from 'react-router-dom';
import v1 from 'api/v1';
import { Button } from '@mui/material';

const formatNumber = (credit: number) => {
  if (credit >= 1000000) {
    return (credit / 1000000).toFixed(1) + 'M';
  } else if (credit >= 1000) {
    return (credit / 1000).toFixed(1) + 'K';
  } else {
    return credit;
  }
};

export default function BuyCredits() {
  const { data } = v1.getCompany();
  const company = data?.data.data || {};
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title="Buy More Credits">
        <Button
          color="secondary"
          variant="outlined" // Or "contained" if you want a solid button
          sx={{
            color: 'text.primary',
            bgcolor: 'transparent',
          }}
          aria-label="buy credits"
          component={Link} // Use Link component for routing
          to="/credits" // Set the path you want to navigate to
        >
          <span style={{ marginRight: 8 }}>{formatNumber(company?.credit)}</span> {/* Add text next to the icon */}
          <EuroOutlined />
        </Button>
      </Tooltip>
    </Box>
  );
}
