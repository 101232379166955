// types
import { DefaultConfigProps } from 'types/config';

// ==============================|| THEME CONSTANT ||============================== //

export const APP_DEFAULT_PATH = '/';
export const HORIZONTAL_MAX_ITEM = 7;
export const DRAWER_WIDTH = 260;
export const MINI_DRAWER_WIDTH = 60;

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum MenuOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum ThemeDirection {
  LTR = 'ltr',
  RTL = 'rtl',
}

export enum NavActionType {
  FUNCTION = 'function',
  LINK = 'link',
}

export enum BackendUrl {
  local = 'http://127.0.0.1:1337',
  gamma = 'https://gamma.api.hackerest.com',
}

export enum GoogleOAuthProviderClientId {
  local = '886183491641-d5vke8rd77k9ldhd7knu2g81ooe4muos.apps.googleusercontent.com',
  gamma = '886183491641-lo82sfps1muaqudugb7h1f2b4u5hptq1.apps.googleusercontent.com',
}

const envName = import.meta.env.VITE_ENV_NAME as keyof typeof BackendUrl;
// ==============================|| THEME CONFIG ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `'Poppins', sans-serif`,
  i18n: 'en',
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  container: true,
  mode: ThemeMode.LIGHT,
  presetColor: 'theme2',
  themeDirection: ThemeDirection.LTR,
  backendUrl: BackendUrl[envName] || BackendUrl.local,
  googleOAuthProviderClientId: GoogleOAuthProviderClientId[envName] || GoogleOAuthProviderClientId.local,
  googleRecaptchaKey: '6Lf15JgqAAAAAM1tf2iJ2GqH6GseAwfvxLXBYlnp', // It can be the same for prod, shouldn't be an issue
};

export default config;
