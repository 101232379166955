/* eslint-disable react-hooks/rules-of-hooks */
import axiosRaw, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { UserProfile } from 'types/auth';
import config from 'config';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { backend_string_2_date } from 'utils/dates';
import { FindingData } from 'pages/finding/edit';
import { queryClient } from 'utils/tanstack';

const setSession = (tokens: { refresh_token: string; token: string } | null) => {
  if (tokens) {
    localStorage.setItem('tokens', JSON.stringify(tokens));
  } else {
    localStorage.removeItem('tokens');
  }
};

const getRefreshToken = () => {
  try {
    return JSON.parse(localStorage.getItem('tokens') || '{}')['refresh_token'];
  } catch {}
  return null;
};

const getAccessToken = () => {
  try {
    return JSON.parse(localStorage.getItem('tokens') || '{}')['token'];
  } catch {}
  return null;
};

const axios = axiosRaw.create({
  baseURL: config.backendUrl,
});

const login = async (data: { email: string; password: string; recaptcha_token: string }): Promise<UserProfile> => {
  const dataRsp = await axios.post('/v1/auth/customer/login', data);
  if (dataRsp.data.success) {
    setSession(dataRsp.data.data);
    return jwtDecode<UserProfile>(dataRsp.data.data.token);
  } else {
    throw new Error(dataRsp.data.message);
  }
};

const googleLogin = async (data: { id_token: string }): Promise<UserProfile> => {
  const dataRsp = await axios.post('/v1/auth/login', data);
  if (dataRsp.data.success) {
    setSession(dataRsp.data.data);
    return jwtDecode<UserProfile>(dataRsp.data.data.token);
  } else {
    throw new Error(dataRsp.data.message);
  }
};

const logout = async () => {
  try {
    await axios.post('/v1/auth/logout', null, {
      headers: {
        'x-refresh-token': getRefreshToken(),
        'x-access-token': getAccessToken(),
      },
    });
  } catch {
  } finally {
    setSession(null);
  }
};

const resetPassword = async (data: { code: string; password: string; confirm_password: string; recaptcha_token: string }) =>
  await axios.post('/v1/auth/reset_password', data);

const forgotPassword = async (data: { email: string; recaptcha_token: string }) => {
  try {
    return await axios.post('/v1/auth/forgot_password', data);
  } catch (error) {
    if (axiosRaw.isAxiosError(error) && error.response) {
      return error.response;
    }
    throw error;
  }
};

const getOrRefreshAccessToken = async (): Promise<UserProfile> => {
  const accessToken = getAccessToken();
  if (!accessToken) throw new Error('Unauthorized: Access Token not found.');

  try {
    const payloadExp = JSON.parse(atob(accessToken.split('.')[1])).exp;
    if (payloadExp && payloadExp > Math.floor(Date.now() / 1000)) return jwtDecode<UserProfile>(accessToken);
  } catch {}

  return refreshAccessToken();
};

const refreshAccessToken = async (): Promise<UserProfile> => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) throw new Error('Unauthorized: Refresh Token not found.');
  const accessToken = getAccessToken();
  if (!accessToken) throw new Error('Unauthorized: Expired Access Token not found.');

  const dataRsp = await axios.post(
    '/v1/auth/session/refresh',
    {
      expired_token: accessToken,
    },
    {
      headers: {
        'x-refresh-token': refreshToken,
      },
    }
  );

  if (dataRsp.status >= 400 && dataRsp.status < 500) {
    throw new Error('Unauthorized: Unable to obtain an access token from the used refresh token.');
  }
  setSession(dataRsp.data.data);
  return jwtDecode<UserProfile>(dataRsp.data.data.token);
};

export type GenericApiResponse<T = any> = {
  data: T;
  message: string;
  success: boolean;
};

const makeApiRequest = async ({
  url,
  data = '',
  method = 'POST',
  invalidate = undefined,
}: {
  url: string;
  data?: any;
  method?: string;
  invalidate?: string;
}): Promise<AxiosResponse<GenericApiResponse>> => {
  if (method !== 'GET' && !invalidate)
    console.debug(
      `Attention: ${url} is being called with method ${method} without invalidating any previous requests. Are you sure this is not creating inconsistencies in the frontend?`
    );
  const issueRequest = async (): Promise<AxiosResponse<GenericApiResponse>> => {
    try {
      const accessToken = getAccessToken();
      const config: AxiosRequestConfig = {
        url,
        method,
        headers: {
          'x-access-token': accessToken,
        },
      };

      method.toUpperCase() === 'GET' ? (config.params = data) : (config.data = data);
      const response = await axios.request(config);
      if (invalidate) {
        queryClient.invalidateQueries({ predicate: query => (query.queryKey[0] + '').startsWith(invalidate) });
      }
      return response;
    } catch (error) {
      // Handle request errors here (like network errors or 5xx server errors)
      throw error; // Rethrow so the outer catch can handle it
    }
  };

  try {
    // First attempt to make the API request
    const response = await issueRequest();
    return response;
  } catch (error) {
    // Handle unauthorized or other specific errors
    if (axiosRaw.isAxiosError(error) && error.response?.status === 401) {
      // Attempt to refresh the access token if 401 Unauthorized
      try {
        await refreshAccessToken();
        // Retry the request after refreshing the token
        return await issueRequest();
      } catch (refreshError) {
        // If refresh fails, handle it (e.g., redirect to login)
        console.error('Error refreshing token:', refreshError);
        // Redirect user to login page or handle the scenario
        window.location.href = '/login'; // Example: redirect to login page
      }
    } else if (axiosRaw.isAxiosError(error) && error.response) {
      // If error is not due to unauthorized access or some other condition, rethrow it
      return error.response;
    }
    throw error;
  }
};

const changePassword = async (data: { old_password: string; new_password: string; confirm_new_password: string }) =>
  makeApiRequest({
    url: '/v1/auth/change_password',
    data,
  });

const createCompany = async (data: { legal_name: string; contacts: string; address: string; credit: number }) =>
  makeApiRequest({
    url: '/v1/company/add',
    data,
    invalidate: '/v1/company',
  });

const createUser = async (data: { name: string; surname: string; email: string; company_id: number }) =>
  makeApiRequest({
    url: '/v1/customer/add',
    data,
    invalidate: '/v1/customer',
  });

const createPentester = async (data: { name: string; surname: string; personal_email: string; nickname: string }) =>
  makeApiRequest({
    url: '/v1/pentester/add',
    data,
    invalidate: '/v1/pentester',
  });

const createAdministrator = async (data: { email: string }) =>
  makeApiRequest({
    url: '/v1/admin/add',
    data,
    invalidate: '/v1/admin',
  });

export type BasicSearchInput =
  | {
      q?: string;
      sort_direction?: 'asc' | 'desc';
      sort_type?: string;
      page?: number;
      items_per_page?: number;
      conditions?: any;
    }
  | undefined;
export type BasicSearchOutput = { items: any[]; total_items: number; page: number; total_pages: number };

const basicList = (
  method: string,
  url: string,
  data: BasicSearchInput
): UseQueryResult<AxiosResponse<GenericApiResponse<BasicSearchOutput>>> =>
  useQuery({
    queryKey: [url, data, method],
    queryFn: () => {
      const params = {
        q: '',
        sort_direction: 'ASC',
        sort_type: '',
        page: 1,
        items_per_page: 5,
        ...data,
        conditions: JSON.stringify(data?.conditions || {}),
      };
      return makeApiRequest({
        method,
        url,
        data: params,
      });
    },
    staleTime: 60 * 1000,
    enabled: !!data,
  });

const customerList = (data: BasicSearchInput): UseQueryResult<AxiosResponse<GenericApiResponse<BasicSearchOutput>>> =>
  basicList('GET', '/v1/customer/list', data);

const companyList = (data: BasicSearchInput): UseQueryResult<AxiosResponse<GenericApiResponse<BasicSearchOutput>>> =>
  basicList('GET', '/v1/company/list', data);

const pentestList = (data: BasicSearchInput): UseQueryResult<AxiosResponse<GenericApiResponse<BasicSearchOutput>>> =>
  basicList('GET', '/v1/pentest/list', data);

const findingList = (data: BasicSearchInput): UseQueryResult<AxiosResponse<GenericApiResponse<BasicSearchOutput>>> =>
  basicList('GET', '/v1/finding/list', data);

const pentesterList = (data: BasicSearchInput): UseQueryResult<AxiosResponse<GenericApiResponse<BasicSearchOutput>>> =>
  basicList('GET', '/v1/pentester/list', data);

const administratorList = (data: BasicSearchInput): UseQueryResult<AxiosResponse<GenericApiResponse<BasicSearchOutput>>> =>
  basicList('GET', '/v1/admin/list', data);

const pentesterAvailabilities = (data?: { q?: string }): UseQueryResult<AxiosResponse<GenericApiResponse<any>>> =>
  useQuery({
    queryKey: ['/v1/pentester/availability', data, 'GET'],
    queryFn: async () => {
      const params = {
        q: '',
        ...data,
      };
      const response = await makeApiRequest({
        method: 'GET',
        url: '/v1/pentester/availability',
        data: params,
      });

      response.data.data.forEach((pentester: any) => {
        pentester.pentests.forEach((pentest: any) => {
          pentest.start_date = pentest.start_date ? backend_string_2_date(pentest.start_date) : null;
          pentest.end_date = pentest.end_date ? backend_string_2_date(pentest.end_date) : null;
        });
      });

      return response; // Return the modified response
    },
    staleTime: 60 * 1000,
  });

type GetCompanyInput = {
  id?: number;
};

const getCompanyNoCache = async (data?: GetCompanyInput, url = '/v1/company/get'): Promise<AxiosResponse<GenericApiResponse<any>>> =>
  makeApiRequest({
    method: 'GET',
    url: url,
    data,
  });

const getCompany = (data?: GetCompanyInput, url = '/v1/company/get'): UseQueryResult<AxiosResponse<GenericApiResponse<any>>> =>
  useQuery({
    queryKey: [url, data],
    queryFn: () =>
      makeApiRequest({
        method: 'GET',
        url: url,
        data,
      }),
    staleTime: 60 * 1000,
  });

const getBuyOptions = (url = '/v1/payment/buy_options'): UseQueryResult<AxiosResponse<GenericApiResponse<any>>> =>
  useQuery({
    queryKey: [url],
    queryFn: () =>
      makeApiRequest({
        method: 'GET',
        url,
      }),
    staleTime: 60 * 1000,
  });

type GetPentestInput = {
  id?: number;
};

const getPentest2 = (data?: GetPentestInput, url = '/v1/pentest/get'): UseQueryResult<AxiosResponse<GenericApiResponse<any>>> =>
  useQuery({
    queryKey: [url, data],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'GET',
        url,
        data,
      });

      // Check if response data is available
      if (response.data && response.data.data) {
        // Parse dates if they exist
        response.data.data.start_date = response.data.data.start_date ? backend_string_2_date(response.data.data.start_date) : null;
        response.data.data.end_date = response.data.data.end_date ? backend_string_2_date(response.data.data.end_date) : null;
      }

      return response; // Return the modified response
    },
    staleTime: 60 * 1000,
    enabled: !!data?.id && data?.id >= 0,
  });

type GetPentesterInput = {
  id?: number | undefined;
};

const getPentester2 = (data?: GetPentesterInput, url = '/v1/pentester/get'): UseQueryResult<AxiosResponse<GenericApiResponse<any>>> =>
  useQuery({
    queryKey: [url, data],
    queryFn: () =>
      makeApiRequest({
        method: 'GET',
        url,
        data,
      }),
    staleTime: 60 * 1000,
  });

type GetFindingInput = {
  id?: number;
};

const getFinding2 = (data?: GetFindingInput, url = '/v1/finding/get'): UseQueryResult<AxiosResponse<GenericApiResponse<any>>> =>
  useQuery({
    queryKey: [url, data],
    queryFn: () =>
      makeApiRequest({
        method: 'GET',
        url,
        data,
      }),
    staleTime: 60 * 1000,
    enabled: !!data?.id && data?.id >= 0,
  });

const getCompanyContract = (enable: boolean, url = '/v1/company/get_contract'): UseQueryResult<AxiosResponse<GenericApiResponse<any>>> =>
  useQuery({
    queryKey: [url],
    queryFn: () =>
      makeApiRequest({
        method: 'GET',
        url,
      }),
    staleTime: 60 * 1000,
    enabled: enable,
  });

const signCompanyContract = async () =>
  makeApiRequest({
    method: 'POST',
    url: '/v1/company/sign_contract',
    invalidate: '/v1/company',
  });

const createPentest = async (data: { name: string; description: string; targets: string[]; n_endpoints: number; n_roles: number }) =>
  makeApiRequest({
    url: '/v1/pentest/add',
    data,
    invalidate: '/v1/pentest',
  });

const getPentest = async (data: { id: number }) =>
  makeApiRequest({
    url: '/v1/pentest/get',
    data,
    method: 'GET',
  });

const getFinding = async (data: { id: number; pentest_id: number }) =>
  makeApiRequest({
    url: '/v1/finding/get',
    data,
    method: 'GET',
  });

const updatePentestDraft = async (data: {
  pentest_id: number;
  name: string;
  description: string;
  targets: string[];
  n_endpoints: number;
  n_roles: number;
}) =>
  makeApiRequest({
    url: '/v1/pentest/draft/edit',
    data,
    invalidate: '/v1/pentest',
  });

const updatePentester = async (data: { id: number; iban: string }) =>
  makeApiRequest({
    url: '/v1/pentester/edit',
    data,
    invalidate: '/v1/pentester',
  });

const updateCompany = async (data: { id: number; legal_name?: string; contracts?: string; address?: string; credit?: number }) =>
  makeApiRequest({
    url: '/v1/company/edit',
    data,
    invalidate: '/v1/company',
  });

const updateFinding = async (data: FindingData) =>
  makeApiRequest({
    url: '/v1/finding/edit',
    data,
    invalidate: '/v1/finding',
  });

const createFinding = async (data: FindingData) =>
  makeApiRequest({
    url: '/v1/finding/add',
    data,
    invalidate: '/v1/finding',
  });

const updatePentest = async (data: any) =>
  makeApiRequest({
    url: '/v1/pentest/edit',
    data: {
      ...data,
      pentesters: data.pentesters
        ? data.pentesters.map((pentester: { id: any }) => (pentester instanceof String ? pentester : pentester.id))
        : [],
    },
    invalidate: '/v1/pentest',
  });

const getSchedulePmCallLink = async (data: { pentest_id: number }) =>
  makeApiRequest({
    url: '/v1/pentest/request_schedule_call_link',
    data,
    method: 'GET',
  });

const setScheduledPmCall = async (data: { pentest_id: number; call_uri?: string }) =>
  makeApiRequest({
    url: '/v1/pentest/pm_call_scheduled',
    data,
    method: 'POST',
    invalidate: '/v1/pentest',
  });

const disableCustomer = async (data: { customer_id: number }) =>
  makeApiRequest({
    url: '/v1/customer/disable',
    data,
    method: 'POST',
    invalidate: '/v1/customer',
  });

const disablePentester = async (data: { id: number }) =>
  makeApiRequest({
    url: '/v1/pentester/disable',
    data,
    method: 'POST',
    invalidate: '/v1/pentester',
  });

const enableCustomer = async (data: { customer_id: number }) =>
  makeApiRequest({
    url: '/v1/customer/enable',
    data,
    method: 'POST',
    invalidate: '/v1/customer',
  });

const getBuyLink = async (data: { payment_package_id: string }) =>
  makeApiRequest({
    url: '/v1/payment/generate_pay_link',
    data,
    method: 'POST',
  });

const findingCategoryList = (
  url = '/v1/finding/categories/list?q=&sort_direction=ASC&sort_type=name&page=1&items_per_page=100000&conditions={}'
): UseQueryResult<AxiosResponse<GenericApiResponse<any>>> =>
  useQuery({
    queryKey: [url],
    queryFn: () =>
      makeApiRequest({
        method: 'GET',
        url,
      }),
    staleTime: 60 * 10000,
  });

const getFindingCategory = (data: { id: string }, url = '/v1/finding/template') =>
  makeApiRequest({
    url,
    data,
    method: 'GET',
  });

const downloadReport = async (data: { pentest_id: number }, url = '/v1/report/download'): Promise<AxiosResponse<Blob>> => {
  try {
    const accessToken = getAccessToken();
    const config: AxiosRequestConfig = {
      url,
      method: 'GET',
      headers: {
        'x-access-token': accessToken,
      },
      params: data,
      responseType: 'blob',
    };
    return axios.request(config);
  } catch (error) {
    throw error;
  }
};

const payPentest = async (data: { pentest_id: number }) =>
  makeApiRequest({
    url: '/v1/pentest/pay',
    data,
    method: 'POST',
    invalidate: '/v1/pentest',
  });

export default {
  login,
  logout,
  googleLogin,
  getOrRefreshAccessToken,
  refreshAccessToken,
  changePassword,
  createCompany,
  updateCompany,
  createUser,
  resetPassword,
  forgotPassword,
  customerList,
  pentestList,
  getCompanyNoCache,
  getCompany,
  getCompanyContract,
  signCompanyContract,
  createPentest,
  updatePentestDraft,
  updatePentest,
  updatePentester,
  getPentest,
  getSchedulePmCallLink,
  setScheduledPmCall,
  disableCustomer,
  disablePentester,
  enableCustomer,
  getPentest2,
  getPentester2,
  getFinding2,
  companyList,
  getBuyOptions,
  getBuyLink,
  pentesterList,
  administratorList,
  pentesterAvailabilities,
  createPentester,
  findingList,
  getFinding,
  updateFinding,
  createFinding,
  findingCategoryList,
  getFindingCategory,
  downloadReport,
  createAdministrator,
  payPentest,
};
