import React, { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { LOGIN, LOGOUT } from 'contexts/auth-reducer/actions';
import authReducer from 'contexts/auth-reducer/auth';

// project import
import Loader from 'components/Loader';
import { AuthProps, JWTContextType } from 'types/auth';

import api from 'api/v1';

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        console.debug('User is logged in');
        const user = await api.getOrRefreshAccessToken();
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user,
          },
        });
      } catch (err) {
        console.debug('User is not logged in');
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, password: string, recaptcha_token: string) => {
    const user = await api.login({ email, password, recaptcha_token });
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const googleLogin = async (id_token: string) => {
    const user = await api.googleLogin({ id_token });
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    // const id = chance.bb_pin();
    // const response = await axios.post('/api/account/register', {
    //   id,
    //   email,
    //   password,
    //   firstName,
    //   lastName
    // });
    // let users = response.data;
    // if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
    //   const localUsers = window.localStorage.getItem('users');
    //   users = [
    //     ...JSON.parse(localUsers!),
    //     {
    //       id,
    //       email,
    //       password,
    //       name: `${firstName} ${lastName}`
    //     }
    //   ];
    // }
    // window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = async () => {
    await api.logout();
    dispatch({
      type: LOGOUT,
      payload: {
        isLoggedIn: false,
      },
    });
  };

  const forgotPassword = async (email: string, recaptcha_token: string) => {
    await api.forgotPassword({ email, recaptcha_token });
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, googleLogin, logout, register, forgotPassword, updateProfile }}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
